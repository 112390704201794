<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            codeGroupCd="EAP_EVAL_CLASS_CD"
            itemText="codeName"
            itemValue="code"
            name="trainingTypeCd"
            label="훈련구분"
            v-model="searchParam.trainingTypeCd"
          ></c-select>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 훈련기간 -->
          <c-datepicker
            :range="true"
            label="훈련기간"
            defaultStart="-6M"
            defaultEnd="6M"
            name="period"
            v-model="period"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="밀폐공간작업 훈련계획 목록"
      rowKey="sopEmergencyTrainingId"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="등록" v-if="editable" icon="add" @btnClicked="onItemClick" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'traning',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'trainingName',
            field: 'trainingName',
            label: '훈련명',
            align: 'left',
            sortable: true,
            type: 'link',
            style: 'width:200px',
          },
          {
            name: 'trainingDate',
            field: 'trainingDate',
            label: '훈련일시',
            align: 'center',
            sortable: true,
            style: 'width:200px',
          },
          // {
          //   name: 'eapTrainingTypeName',
          //   field: 'eapTrainingTypeName',
          //   label: '훈련구분',
          //   align: 'center',
          //   sortable: true,
          //   style: 'width:100px',
          // },
          {
            name: 'trainingArea',
            field: 'trainingArea',
            label: '훈련대상 밀폐공간',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'trainingCompleteFlagName',
            field: 'trainingCompleteFlagName',
            label: '훈련완료여부',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
          {
            name: 'trainingInstructUserNames',
            field: 'trainingInstructUserNames',
            label: '교관',
            align: 'left',
            sortable: true,
            style: 'width:200px',
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        trainingTypeCd: null,
        useFlag: 'Y',
        startYmd: '',
        endYmd: '',
      },
      period: [],
      searchUrl: '',
      editable: true,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.eap.training.plan.closedList.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    onItemClick() {
      this.linkClick(null);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = '밀폐공간작업 훈련계획 상세'; // 밀폐공간작업 훈련계획 상세
      this.popupOptions.param = row ? row : {
        sopEmergencyTrainingId: '',
        eapTrainingTypeCd: null,
        plantCd: null,
      };
      this.popupOptions.target = () => import(`${'./closedSpaceEduDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
